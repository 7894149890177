var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "branches-table",
      on: {
        click: function ($event) {
          $event.stopPropagation()
        },
      },
    },
    [
      _vm.categoriesName.length === 1
        ? _c("div", {
            staticClass: "span",
            domProps: { textContent: _vm._s(_vm.categoriesName[0]) },
          })
        : _c(
            "button",
            {
              on: {
                click: function ($event) {
                  _vm.openedCataegory = !_vm.openedCataegory
                },
              },
            },
            [
              _c("span", {
                ref: "span",
                class: _vm.data.class,
                domProps: {
                  textContent: _vm._s(
                    _vm.categoriesName.length + " " + _vm.$t("Categories")
                  ),
                },
              }),
            ]
          ),
      _vm.openedCataegory
        ? [
            _c("div", {
              staticClass: "shadow-site is-visible md-block",
              on: {
                click: function ($event) {
                  _vm.openedCataegory = !_vm.openedCataegory
                },
              },
            }),
            _c(
              "div",
              {
                staticClass: "table-search-popup",
                class: { intop: _vm.data.index < 4 },
                style: _vm.style,
              },
              [
                _c(
                  "div",
                  { staticClass: "table-search-popup__content" },
                  _vm._l(_vm.categoriesName, function (name) {
                    return _c(
                      "div",
                      { key: name, staticClass: "table-search-popup__item" },
                      [
                        _c("div", {
                          staticClass: "table-search-popup__title",
                          domProps: { textContent: _vm._s(name) },
                        }),
                      ]
                    )
                  }),
                  0
                ),
              ]
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }